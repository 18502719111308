@use '@awsui/design-tokens/index' as awsui;

* {
  font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;
}

body {
  margin: 0;
  background: awsui.$color-background-layout-main;
}

ul.menu-list {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0;
  padding: 0 40px;
  list-style: none;
  font-size: 16px;

  & > li {
    padding: 0;
    margin: 0;
    margin-right: 8px;

    > a {
      padding: 0 6px;
    }

    a,
    div,
    button,
    input,
    label {
      float: left;
      color: awsui.$color-text-interactive-default;
      line-height: 16px;
    }

    #visual-refresh-toggle {
      margin-right: 5px;
      margin-top: 1px;
    }

    a,
    a:hover {
      cursor: pointer;
      text-decoration: none;
    }

    &.title {
      font-weight: bold;
    }

    &.selected > a,
    &.selected > button {
      font-weight: bold;
      color: awsui.$color-text-accent;
    }

    &.separated {
      border-left: 1px solid awsui.$color-border-control-default;
      margin-right: 0;
      margin-left: 0;
    }

    &.ml-auto {
      margin-left: auto;
    }

    &.separator {
      width: 2px;
      height: 16px;
      background: #e9ebed;
      padding: 0;
      margin-right: 24px;
      margin-left: 24px;
    }
  }

  button,
  button:focus {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  li.checkbox-item {
    margin-right: 0;
  }
}
