@use './header';
@use '@awsui/design-tokens/index' as awsui;

// https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
.custom-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.statement-orange {
  font-weight: bold;
  color: orange;
}

.statement-grey {
  font-weight: bold;
  color: grey;
}

.file-select-token {
  width: 90%;
  border-width: 1px;
  border-style: solid;
  border-color: #cccccc;
  background-color: #f3fbfd;
}

.vertical-separator {
  border-left: solid 1px awsui.$color-border-input-default;
  height: 100%;
}

.custom-spacer-horizontal-s {
  align-items: center;
  display: flex;
  gap: awsui.$space-static-s;
}

.word-break {
  word-break: break-word;
}

.watchers-button {
  padding-top: 0px;
}

.tooltip-container {
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: #fff;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 240px;
  font-size: 12px;
  padding: 15px;
  border-radius: 5px;
  direction: ltr;
  box-shadow: 0px 1px 4px -2px rgba(0, 28, 36, 0.5);
  border: 1px solid #d5dbdb;
}

.caret {
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 0;
  border-bottom: 20px solid #fff;
  top: -3%;
  left: 50%;
  transform: translateX(-50%);
}

.watchers-title {
  padding: 5px 0px 15px 0px;
  margin: 0px;
}

nav p.last-login{
    font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;
    color: var(--color-text-body-secondary-bgqgd0, #545b64);
    margin-left: 32px;
    font-size: 14px;
}

